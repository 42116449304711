import { sortByProperty, sortByOrder } from '@/helpers/sort';

const links = {
  'Annual Reviews in Control': 'https://www.sciencedirect.com/journal/annual-reviews-in-control',
  'Control Engineering Practice': 'https://www.sciencedirect.com/journal/control-engineering-practice',
  'Engineering Applications of Artificial Intelligence': 'https://www.sciencedirect.com/journal/engineering-applications-of-artificial-intelligence',
  'IFAC Journal of Systems and Control': 'https://www.sciencedirect.com/journal/ifac-journal-of-systems-and-control',
  'IFAC-PapersOnLine': 'https://www.sciencedirect.com/journal/ifac-papersonline',
  'Journal of Process Control': 'https://www.sciencedirect.com/journal/journal-of-process-control',
  'Nonlinear Analysis : Hybrid Systems': 'https://www.sciencedirect.com/journal/nonlinear-analysis-hybrid-systems',
  Automatica: 'https://www.sciencedirect.com/journal/automatica',
  Mechatronics: 'https://www.sciencedirect.com/journal/mechatronics',
  NewsLetter: 'https://www.ifac-control.org/publications/newsletters',
};

export const gridDefaultColDef = {
  resizable: true,
  sortable: true,
};

export function gridColumns(ProfileLink) {
  return [
    {
      headerName: 'Journal',
      flex: 1,
      sortable: false,
      sort: 'asc',
      sortIndex: 0,
      valueGetter: (params) => {
        const { positions } = params.data;

        return positions
          .map((p) => p.parent?.name ?? '')
          .join(', ');
      },
      cellRenderer: (params) => {
        const { positions } = params.data;

        return positions
          .filter((p) => p.parent !== null)
          .map((p) => {
            const link = links[p.parent?.name];

            if (link) {
              return `<a target="_blank" href="${link}">${p.parent?.name}</a>`;
            }

            return `<div>${p.parent?.name}</div>`;
          })
          .join('');
      },
    },
    {
      headerName: 'Role',
      flex: 1,
      sortable: false,
      sort: 'asc',
      sortIndex: 1,
      autoHeight: true,
      valueGetter: (params) => params.data.positions[0].name,
      cellRenderer: (params) => {
        const { positions } = params.data;

        return positions
          .map((p) => `<div>${p.name}</div>`)
          .join('');
      },
      comparator: (valueA, valueB) => sortByOrder(valueA, valueB, ['Editor-in-Chief', 'Deputy Editor-in-Chief']),
    },
    {
      headerName: 'Full Name',
      flex: 1,
      sortable: false,
      sortIndex: 2,
      sort: 'asc',
      valueGetter: (params) => params.data,
      cellRenderer: ProfileLink,
      comparator: (valueA, valueB) => sortByProperty(valueA, valueB, 'surname'),
    },
  ];
}
